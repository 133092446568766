import { SupplierClient } from '@/clients/supplierClient';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { AddressModel } from '@/models/addressModel';
import {
  CompanyModel,
  CompanyNameModel,
  companyResponseModel,
} from '@/models/companyModel';
import { Guid } from '@/models/guid';
import {
  InvitedSupplierModel,
  LinkSupplierResponeModel,
} from '@/models/invitedSupplierModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { SignedComplianceDocResponse } from '@/models/signedComplianceDocResponse';
import {
  CompanyPictureModel,
  CompanyPictureReqModel,
} from '@/models/companyPictureModel';
import { SupplierEvidence } from '@/clients/supplierEvidence';
import {
  DelegateCompanyModel,
  OrderDelegateCompanyModel,
  OrderCompanyRespModel,
} from '@/models/delegateCompanyModel';

import store from '@/store';
import { TCMailModel } from '@/models/transcationCertificateModal';
import { SupplierBlockchain } from '@/clients/supplierBlockchain';
import { EsgEnvDocUrlReq, EsgEnvDocUrlResp, EsgEnvModel, EsgEnvRespModel, EsgFacilityAddressInfo, EsgSocialAuditCert, EsgSocialAuditCertResp, EsgSocialDocUrlReq, EsgSocialDocUrlResp, EsgSocialModel, EsgSocialRespModel, EsgUploadDocRespModel } from '@/models/esgModel';
import { ActionTypes } from '@/store/actions/actions';
import { MandatoryStepsRuleResponse } from '@/models/mandatoryStepsModel';

export class SupplierService {
  private readonly client: SupplierClient = new SupplierClient();
  private readonly supplier: SupplierEvidence = new SupplierEvidence();
  private readonly supplierBlockchain: SupplierBlockchain =
    new SupplierBlockchain();

  public async getSupplierAsync(): Promise<CompanyModel> {
    const result = await this.client.getSupplierAsync();
    store.commit('setProfile', result);
    store.commit('setMyAccount', result);
    return result;
  }

  public async downloadSignedPdf(
    data: any
  ): Promise<SignedComplianceDocResponse> {
    const result = await this.client.downloadSignedPdf(data);
    return result;
  }

  public async getSupplierNameByIdAsync(supplierId: string): Promise<string> {
    const result = await this.client.getSupplierNameByIdAsync(supplierId);
    return result;
  }

  public async getSupplyChainPartnersAsync(): Promise<OrderCompanyModel[]> {
    const result = await this.client.getSupplyChainPartnersAsync();
    store.commit('setSuppliers', result);
    return result;
  }

  public async updateSupplierAsync(
    supplier: CompanyModel
  ): Promise<companyResponseModel> {
    const response = await this.client.updateSupplierAsync(supplier);
    if (
      response.result === 'success' &&
      response.responseObject.companyName !== ''
    ) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.supplier_update_succeeded').toString()
      );
    }
    return response;
  }

  public async updateSupplierAddressAsync(
    supplierId: string,
    address: AddressModel
  ): Promise<string> {
    try {
      const result = await this.client.updateSupplierAddressAsync(
        supplierId,
        address
      );
      NotificationHelper.createSucceededNotification(
        i18n
          .t('global.notifications.supplier_address_update_succeeded')
          .toString()
      );
      return result;
    } catch {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return Guid.Empty;
    }
  }

  public async linkSupplierAsync(
    invitedSupplierModel: InvitedSupplierModel
  ): Promise<LinkSupplierResponeModel> {
    return await this.client.linkSupplierAsync(invitedSupplierModel);
  }

  public async uploadCompanyPicturesAsync(
    companyId: string,
    pictures: CompanyPictureModel[]
  ): Promise<void> {
    await this.supplier.uploadCompanyPicturesAsync(companyId, pictures);
  }

  public async submitCompanyPicturesAsync(
    pictures: CompanyPictureReqModel,
    companyId: string
  ): Promise<Response> {
    const result = await this.supplier.submitCompanyPicturesAsync(
      pictures,
      companyId
    );
    if (result) {
      return result;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return result;
    }
  }

  public async uploadProfilePictureAsync(
    userId: string,
    data: FormData
  ): Promise<boolean> {
    const result = await this.supplier.uploadProfilePictureAsync(userId, data);
    if (result) {
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.user_profile_upload').toString()
      );
      return true;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return false;
    }
  }

  public async updateProfilePictureAsync(
    userId: string,
    data: FormData
  ): Promise<boolean> {
    const result = await this.supplier.updateProfilePictureAsync(userId, data);
    if (result) {
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.user_profile_update').toString()
      );
      return true;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return false;
    }
  }

  public async deleteProfilePictureAsync(
    userId: string,
    profileId: string
  ): Promise<void> {
    const result = await this.supplier.deleteProfilePictureAsync(
      userId,
      profileId
    );
    NotificationHelper.createSucceededNotification(
      i18n.t('global.notifications.user_profile_delete').toString()
    );
  }

  public async deleteCompanyPictureAsync(
    companyId: string,
    pictureId: string
  ): Promise<void> {
    const result = await this.supplier.deleteCompanyPictureAsync(
      companyId,
      pictureId
    );
  }

  public async getSuppliersListAsync(
    supplierId: string
  ): Promise<OrderCompanyModel[]> {
    const result = await this.supplier.getSuppliersListAsync(supplierId);
    store.commit('setSuppliers', result.supplier);
    return result.supplier;
  }

  // TTD-2422 delegate partner object save into ledger
  public async updateDelegatePartnerAsync(
    delegateCompany: OrderDelegateCompanyModel,
    supplierId: string
  ): Promise<OrderCompanyRespModel> {
    const result = await this.supplier.updateDelegatePartnerAsync(
      delegateCompany,
      supplierId
    );
    return result;
  }

  // TTD-4486 sprint-24
  public async sendTCUploadMail(tcMailData: TCMailModel): Promise<Response> {
    return await this.client.sendTCUploadMail(tcMailData);
  }

  // TTD-3691 get company name
  public async getCompanyNameAsync(): Promise<CompanyNameModel[]> {
    const result = await this.supplierBlockchain.getCompanyNameAsync();
    if (result.success) {
      const companies = JSON.parse(
        decodeURIComponent(escape(window.atob(result.companies)))
      );
      return companies;
    }
    return [];
  }

  // TTD-4716 save social esg data
  public async saveSocialEsgDataAsync(
    socialData: EsgSocialModel
  ): Promise<EsgSocialRespModel> {
    const result =  await this.supplierBlockchain.saveSocialEsgDataAsync(socialData);
    if(result.success){
      const socialData = store.getters.esgSocial as EsgSocialModel[];
      const socialIndex = socialData.findIndex(s => s.ID === result.socialData[0].ID);
      if(socialIndex !== -1){
        socialData.splice(socialIndex, 1, result.socialData[0]);
      } else {
        socialData.push(result.socialData[0]);
      }
      store.commit(ActionTypes.SET_ESG_SOCIAL_DATA, socialData);
      NotificationHelper.createSucceededNotification(
        i18n.t('pages.esg.social_esg_save_success').toString()
      );
      return result;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.esg.social_esg_save_fail').toString()
      );
      return result;
    }
  }

  // TTD-4716 save social esg data
  public async getSocialEsgDataAsync(
    companyID: string
  ): Promise<EsgSocialRespModel> {
    const result =  await this.supplierBlockchain.getSocialEsgDataAsync(companyID);
    return result;
  }

  // TTD-4716 save social esg data on address
  public async getSocialEsgDataOnFacilityAsync(
    addressID: string
  ): Promise<EsgSocialRespModel> {
    const result =  await this.supplierBlockchain.getSocialEsgDataOnFacilityAsync(addressID);
    if(result.success){
      const socialData = store.getters.esgSocial as EsgSocialModel[];
      if(result.socialData.length > 0){
        const socialIndex = socialData.findIndex(s => s.ID === result.socialData[0].ID);
        if(socialIndex !== -1){
          socialData.splice(socialIndex, 1, result.socialData[0]);
        } else {
          socialData.push(result.socialData[0]);
        }
        store.commit(ActionTypes.SET_ESG_SOCIAL_DATA, socialData);
      }
    }
    return result;
  }

  public async getEnvEsgDataOnFacilityAsync(
    addressID: string
  ): Promise<EsgEnvRespModel> {
    const result =  await this.supplierBlockchain.getEnvEsgDataOnFacilityAsync(addressID);
    if(result.success){
      const envData = store.getters.esgEnv as EsgEnvModel[];
      if(result.envData.length > 0){
        const envIndex = envData.findIndex(s => s.ID === result.envData[0].ID);
        if(envIndex !== -1){
          envData.splice(envIndex, 1, result.envData[0]);
        } else {
          envData.push(result.envData[0]);
        }
        store.commit(ActionTypes.SET_ESG_ENV_DATA, envData);
      }
    }
    return result;
  }

  // TTD-2667 upload esg social audit certificates
  public async uploadEsgSocialAuditCertAsync(
    esgSocialAuditCertData: EsgSocialAuditCert
  ): Promise<EsgSocialAuditCertResp> {
    const result = await this.supplierBlockchain.uploadEsgSocialAuditCertAsync(
      esgSocialAuditCertData
    );
    if(result.success){
      if(esgSocialAuditCertData.certificates[0].type === '2' && esgSocialAuditCertData.certificates.length === 1){
        await store.dispatch(ActionTypes.SET_ESG_SOCIAL_DATA);
        NotificationHelper.createSucceededNotification(
          i18n.t('pages.esg.amfori_doc_upload_success').toString()
        );
      }else{
        await store.dispatch(ActionTypes.SET_ESG_SOCIAL_DATA);
        NotificationHelper.createSucceededNotification(
          i18n.t('pages.esg.social_audit_cert_save_success').toString()
        );
      }
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.esg.social_audit_cert_save_fail').toString()
      );
    }
    return result;
  }

  // TTD-4716 upload proof & policy docs
  public async uploadSocialEsgDocsAsync(uploadKey:string, uploadData: FormData): Promise<EsgUploadDocRespModel> {
    const result = await this.supplierBlockchain.uploadSocialEsgDocsAsync(uploadData);
    if(result.success){
      if(uploadKey === 'sdsaQ03Docs'){
        NotificationHelper.createSucceededNotification(
          i18n.t('pages.esg.corrective_doc_success').toString()
        );
      }else{
        NotificationHelper.createSucceededNotification(
          i18n.t('pages.esg.upload_doc_success').toString()
        );
      }
      const data = uploadData.get('addressInfoForFacility');
      const addressInfoForFacility: EsgFacilityAddressInfo[] = JSON.parse(data as string);
      const res = await this.supplierBlockchain.getSocialEsgDataOnFacilityAsync(addressInfoForFacility[0].addressID)
      if(res.success){
        const socialData = store.getters.esgSocial as EsgSocialModel[];
        if(res.socialData.length > 0){
          const socialIndex = socialData.findIndex(s => s.ID === res.socialData[0].ID);
          if(socialIndex !== -1){
            socialData.splice(socialIndex, 1, res.socialData[0]);
          } else {
            socialData.push(res.socialData[0]);
          }
          store.commit(ActionTypes.SET_ESG_SOCIAL_DATA, socialData);
        }
      }
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.esg.upload_doc_fail').toString()
      );
    }
    return result;
  }

  // TTD-4716 get doc url
  public async getDocUrlAsync(doc: EsgSocialDocUrlReq): Promise<EsgSocialDocUrlResp> {
    const result = await this.supplierBlockchain.getDocUrlAsync(doc);
    return result;
  }

  // TTD-4716 get environmental esg data
  public async getEnvEsgDataAsync(
    companyID: string
  ): Promise<EsgEnvRespModel> {
    const result =  await this.supplierBlockchain.getEnvEsgDataAsync(companyID);
    return result;
  }

  // TTD-4716 save env esg data
  public async saveEnvEsgDataAsync(
    envData: EsgEnvModel
  ): Promise<EsgEnvRespModel> {
    const result =  await this.supplierBlockchain.saveEnvEsgDataAsync(envData);
    if(result.success){
      const envData = store.getters.esgEnv as EsgEnvModel[];
      const envIndex = envData.findIndex(s => s.ID === result.envData[0].ID);
      if(envIndex !== -1){
        envData.splice(envIndex, 1, result.envData[0]);
      } else {
        envData.push(result.envData[0]);
      }
      store.commit(ActionTypes.SET_ESG_ENV_DATA, envData);
      NotificationHelper.createSucceededNotification(
        i18n.t('pages.esg.social_esg_save_success').toString()
      );
      return result;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.esg.social_esg_save_fail').toString()
      );
      return result;
    }
  }

  // TTD-981 upload env supporting docs
  public async uploadEnvEsgDocsAsync(uploadData: FormData): Promise<EsgUploadDocRespModel> {
    const result = await this.supplierBlockchain.uploadEnvEsgDocsAsync(uploadData);
    if(result.success){
      NotificationHelper.createSucceededNotification(
        i18n.t('pages.esg.upload_doc_success').toString()
      );
      const data = uploadData.get('addressInfoForFacility');
      const addressInfoForFacility: EsgFacilityAddressInfo[] = JSON.parse(data as string);
      const res = await this.supplierBlockchain.getEnvEsgDataOnFacilityAsync(addressInfoForFacility[0].addressID);
      if(res.success){
        const envData = store.getters.esgEnv as EsgEnvModel[];
        if(res.envData.length > 0){
          const envIndex = envData.findIndex(s => s.ID === res.envData[0].ID);
          if(envIndex !== -1){
            envData.splice(envIndex, 1, res.envData[0]);
          } else {
            envData.push(res.envData[0]);
          }
          store.commit(ActionTypes.SET_ESG_ENV_DATA, envData);
        }
      }
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.esg.upload_doc_fail').toString()
      );
    }
    return result;
  }

  // TTD-4716 get env doc url
  public async getEnvDocUrlAsync(doc: EsgEnvDocUrlReq): Promise<EsgEnvDocUrlResp> {
    const result = await this.supplierBlockchain.getEnvDocUrlAsync(doc);
    return result;
  }

  // TTD-5020, sprint 28
  public async getMandatoryStepsRuleAsync(clientID: string, brand: string, cpID: string): Promise<MandatoryStepsRuleResponse> {
    return await this.supplierBlockchain.getMandatoryStepsRuleAsync(clientID, brand, cpID);
  }
}
