import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { Drag, Drop, DropList } from 'vue-easy-dnd';
import i18n from '@/i18n';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import { mapState } from 'vuex';
import lodash, { cloneDeep } from 'lodash';

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import SearchPartner from '@/components/general/search-partner/searchPartner.vue';
import enLangData from "@/locales/en.json";

// Service
import { ProductGroupService } from '@/services/productGroupService';
import { SupplyChainStepService } from '@/services/supplyChainStepService';
import { SupplierService } from '@/services/supplierService';
import { OrderService } from '@/services/orderService';

// Helper
import { DateTimeHelper } from '@/helpers/dateTimeHelper';
import { StepsHelper } from '@/helpers/stepsHelper';

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { OrderLineModel } from '@/models/orderLineModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { SupplyChainStepModel } from '@/models/supplyChainStepModel';
import { Guid } from '@/models/guid';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepParallelModel } from '@/models/orderLineStepParallelModel';
import { ProductGroupModel } from '@/models/productGroupModel';
import { SupplierStepModel } from '@/models/supplierStepModel';
import { StepState } from '@/models/stepState';
import { StepHistory } from '@/models/stepHistoryModal';
import { UserModel } from '@/models/userModel';
import { InviteDropdownModel } from '@/models/inviteDropdownModel';
import { DelegateCompanyModel } from '@/models/delegateCompanyModel';
import { CompanyNameModel } from '@/models/companyModel';
import { MandatoryProcessStepIDsModel, MandatoryStepsProcessModel } from '@/models/mandatoryStepsModel';

@Component({
  components: { Drag, Drop, DropList, SearchSupplier, SearchPartner },
  computed: mapState(['suppliers', 'companyNameList'])
})
export default class CreateSupplyChain extends Vue {
  @Prop()
  private orderLine!: OrderLineDisplayModel[];

  @Prop()
  private orderLineStep!: OrderLineStepModel[];

  @Prop()
  private isBulk!: boolean;

  @Prop()
  private lineSteps!: OrderLineStepModel[];

  @Prop()
  private draftOrderLineSteps!: OrderLineStepModel[];

  private order: OrderLineModel = new OrderLineModel();

  private defaultOrderLine: OrderLineModel = new OrderLineModel();

  private orderService: OrderService;

  private suppliers!: OrderCompanyModel[];

  private productGroupService: ProductGroupService;

  private supplierService: SupplierService;

  private supplyChainStepService: SupplyChainStepService;

  private orderLineSteps: OrderLineStepModel[] = [];

  private clientOrderLineSteps: OrderLineStepDisplayModel[] = [];

  private selectedProductGroup: number = 0;

  private selectedScp: string = Guid.Empty;

  private selectedStep: number = 0;

  private selectedParallelStep: number = 0;

  private allProductGroups: ProductGroupModel[] = [];

  private deleteDisabled: boolean = true;

  private draggedStep: number = -1;

  private assignNameToOtherNewStep: boolean = false;

  private addingInProgress: boolean = false;

  private allSteps: SupplierStepModel[] = [];

  private stepsForProductGroup: SupplyChainStepModel[] = [];

  private allSupplyChainPartners: OrderCompanyModel[] = [];

  private showSearch: boolean = false;

  private isCreating: boolean = false;

  private isLoading: boolean = false;

  private stepIndex: number = -1;

  private customisedCount: number = 0;

  private clientId: string = '';

  private orderRefId: string = Guid.Empty;

  private clientSteps: OrderLineStepModel[] = [];

  private supplierChanged: boolean = false;

  private showDraft: boolean = false;

  private showBack: boolean = false;

  private companyStepHistory: StepHistory[] = [];

  private company: UserModel = new UserModel();

  private indexOfChain: number = -1;

  private indexOfChainStep: number = -1;

  private stepNames: string[] = [];

  private hasChild: string[] = [];

  private parentStepId: number = 0;

  private showMaxParallelChainModal: boolean = false;

  private parallelChain0DropdownSteps: DropdownModel[] = [];

  private parallelChain1DropdownSteps: DropdownModel[] = [];

  private parallelChain2DropdownSteps: DropdownModel[] = [];

  private parallelChain3DropdownSteps: DropdownModel[] = [];

  private parallelChain4DropdownSteps: DropdownModel[] = [];

  private isNewStepAdded: boolean = false;

  private showRemoveStepModal: boolean = false;

  private stepIdToRemove: number = -1;

  private showHover: boolean = true;

  private paddingLeft: number = 0;

  private isDragging: boolean = false;

  private showTier1: boolean = false;

  private showSupplier: boolean = false;

  private showSupplierForParallel: boolean = false;

  private showSupplierForDelegated: boolean = false;

  private isParallelSupplyChain: boolean = false;

  private isParallel: boolean = false;

  private wantToDelegate: boolean = false;

  private delegatedPartnerName: string = '';

  private isDelegatedToCp: boolean = false;

  private isCustomisedByDelegator: boolean = false;

  private isShowRemoveDelegator: boolean = false;

  private isDelegate: boolean = false;

  private selectedDelegateObj: DelegateCompanyModel = new DelegateCompanyModel('', '', '', false, false);

  private isDelegatedPartnerRemoved: boolean = false;
  // TTD-3619
  private isShowAddRefModal: boolean = false;

  private toCompanyId: string = '';

  private chainType: string = '';

  private referenceNumber: string = '';

  private companyNameList!: CompanyNameModel[];

  private isEmptyStepName: boolean = false;

  //commenting these changes not required now sprint-18
  // private defaultSteps: OrderLineStepModel[] = [];

  // TTD-5020
  private showMandatoryStepModal: boolean = false;
  private mandatoryStepProcesses: string[] = [];
  private mandatoryStepAllProcess: MandatoryStepsProcessModel[] = [];
  private mandatoryProcesses: MandatoryStepsProcessModel[] = [];
  private processStepMissing: string[] = [];
  private mandatoryStepModalType: string = 'INFO';

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.supplyChainStepService = new SupplyChainStepService();
    this.productGroupService = new ProductGroupService();
    // this.clientSteps = this.orderLineStep;
    // this.showDraft = this.isBulk ? true : false;
    this.stepNames = Object.values(enLangData.enums.steps);
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.clientSteps = cloneDeep(this.orderLineStep);
      this.showDraft = this.isBulk ? true : false;
      this.company = this.$store.getters.user as UserModel;
      await this.getOrderLine();
      await this.getOrderLineSteps();
      await this.getSupplyChainPartners();
      await this.getSupplyChainSteps();
      if (
        this.selectedProductGroup != null &&
        this.selectedProductGroup !== 0
      ) {
        await this.getSupplyChainStepsForProductGroup(
          this.selectedProductGroup
        );
      }
      this.setChainCount();
    } finally {
      setTimeout(
        async (app: any) => {
          document
            .querySelector('.step-list')
            ?.setAttribute('id', 'step-list-custom-scroll');
          app.calculateDistance(true);
        },
        250,
        this
      );
      const response = await this.supplierService.getMandatoryStepsRuleAsync(this.orderLine[0].fromCompanyUser.ID, this.orderLine[0].orderLines[0].brandName, this.orderLine[0].toCompanyId);
      if(response.success){
          this.mandatoryStepProcesses = response.processes;
          if(this.mandatoryStepProcesses.length > 0){
              this.mandatoryStepAllProcess = StepsHelper.getMandatoryStepProcess();
              this.mandatoryStepProcesses.forEach(processID => {
                const process = this.mandatoryStepAllProcess.find(process => process.processID === processID)
                if(process !== undefined){
                    this.mandatoryProcesses.push(process);
                }
            })
          }
      }
      this.isLoading = false;
    }
    this.checkStepsCustomised();
  }

  // TTD-5020, Sprint 28
  private getSteps(step:MandatoryProcessStepIDsModel[]): string{
    return step.map(s => s.stepName).join(', ');
  }

  // TTD-5020
  private showMandatoryStepModalPopup(type: string): void {
    if(!this.showMandatoryStepModal){
      this.mandatoryStepModalType = type;
      this.mandatoryProcesses = [];
      if(type === 'INFO'){
        this.mandatoryStepProcesses.forEach(processID => {
            const process = this.mandatoryStepAllProcess.find(process => process.processID === processID)
            if(process !== undefined){
                this.mandatoryProcesses.push(process);
            }
        })
      } else if(type === 'MISSING') {
        this.processStepMissing.forEach(processID => {
            const process = this.mandatoryStepAllProcess.find(process => process.processID === processID)
            if(process !== undefined){
                this.mandatoryProcesses.push(process);
            }
        })
      }
    }
    this.showMandatoryStepModal = !this.showMandatoryStepModal;
  }

  // TTD-2630 Sprint 18
  private checkbyWhom(order: OrderLineModel): boolean {
    const user = this.$store.getters.user as UserModel;
    if (order.delegateCompanyID !== user.companyId) {
      return true;
    }
    return false;
  }

  private setChainCount(): void {
    let count = 0;
    this.isParallelSupplyChain = false;
    this.orderLineSteps.forEach((o) => {
      o.parallelChainCount = count;
      if (o.parallelSupplyChain0!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain1!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain2!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain3!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain4!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
    });
  }

  private isLastStep(stepId: string): boolean {
    const index = this.orderLineSteps.findIndex(
      (s) => s.stepId === Number.parseInt(stepId)
    );
    if (this.orderLineSteps.length === index + 1) {
      return true;
    }
    return false;
  }

  private get getGuid(): string {
    return Guid.Empty;
  }

  private setShowSearch(): void {
    this.showSearch = true;
    this.isDelegate = true;
  }

  private setDelegated(id: string): void {
    //here we will get delegated partner id
    this.showSearch = false;
    this.isDelegate = false;
    const sdp = this.allSupplyChainPartners.filter((s) => s.id === id)[0];
    if(sdp!==undefined){
      this.delegatedPartnerName = sdp.companyName;
      this.selectedDelegateObj = new DelegateCompanyModel(id, sdp.companyName, sdp.country, sdp.isCompliant, sdp.onBoarded);
      this.selectedDelegateObj.companyEmail = sdp.companyEmail;
      this.selectedDelegateObj.firstName = sdp.firstName;
      this.selectedDelegateObj.invitedPersonEmailID = sdp.invitedPersonEmailID;
      this.selectedDelegateObj.isAgent = sdp.isAgent;
      this.selectedDelegateObj.isCompliant = sdp.isCompliant;
      this.selectedDelegateObj.isTier1 = sdp.isTier1;
      this.selectedDelegateObj.lastName = sdp.lastName;
    }
  }

  private removeParallelChainOrStep(
    stepIdText: string,
    parentStepIdText: string,
    indexOfChain: number
  ) {
    const stepId = Number.parseInt(stepIdText);
    const ParentStepId = Number.parseInt(parentStepIdText);
    const step = this.getParallelChain(indexOfChain, parentStepIdText).filter(
      (c) => c.stepId == stepId
    )[0];
    const user = this.$store.getters.user as UserModel;
    if (
      (step.supplierStep === false && step.stepState < StepState.CREATED_BY_DELEGATEPARTNER) &&
      step.toCompanyId !== Guid.Empty && this.order.delegateCompanyID === user.companyId
    ) {
      return;
    } else if (
      (step.supplierStep === false && (step.stepState < StepState.CREATED_BY_SUPPLIER || step.stepState > StepState.UPDATED_BY_SUPPLIER)) &&
      step.toCompanyId !== Guid.Empty && this.order.delegateCompanyID !== user.companyId
    ) {
      return;
    }
    const indexOfStep = this.getParallelChain(
      indexOfChain,
      parentStepIdText
    ).findIndex((s) => s.stepId === stepId);
    const allStepsInParallel = this.getParallelChain(
      indexOfChain,
      parentStepIdText
    );
    if (allStepsInParallel.length > 1) {
      if (indexOfChain == 0) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain0?.splice(indexOfStep, 1);
      } else if (indexOfChain == 1) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain1?.splice(indexOfStep, 1);
      } else if (indexOfChain == 2) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain2?.splice(indexOfStep, 1);
      } else if (indexOfChain == 3) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain3?.splice(indexOfStep, 1);
      } else if (indexOfChain == 4) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain4?.splice(indexOfStep, 1);
      }
    } else {
      switch (indexOfChain) {
        case 0:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain0 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain0 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(0, parentStepIdText);
          break;
        case 1:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(1, parentStepIdText);
          break;
        case 2:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(2, parentStepIdText);
          break;
        case 3:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(3, parentStepIdText);
          break;
        default:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(4, parentStepIdText);
          break;
      }
      this.setChainCount();
    }
  }

  private remove(stepIdText: string): void {
    const stepId = Number.parseInt(stepIdText);
    const parentStep = this.orderLineSteps.filter((o) => o.stepId === stepId);
    const user = this.$store.getters.user as UserModel;
    if (
      (parentStep[0].supplierStep === false && parentStep[0].stepState < StepState.CREATED_BY_DELEGATEPARTNER) &&
      parentStep[0].toCompanyId !== Guid.Empty && this.order.delegateCompanyID === user.companyId
    ) {
      return;
    } else if (
      (parentStep[0].supplierStep === false && (parentStep[0].stepState < StepState.CREATED_BY_SUPPLIER || parentStep[0].stepState > StepState.UPDATED_BY_SUPPLIER)) &&
      parentStep[0].toCompanyId !== Guid.Empty && this.order.delegateCompanyID !== user.companyId
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain0.length > 0 &&
      parentStep[0].parallelSupplyChain0.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain1.length > 0 &&
      parentStep[0].parallelSupplyChain1.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain2.length > 0 &&
      parentStep[0].parallelSupplyChain2.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain3.length > 0 &&
      parentStep[0].parallelSupplyChain3.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain4.length > 0 &&
      parentStep[0].parallelSupplyChain4.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    }
    if (
      parentStep[0].parallelSupplyChain0?.length == 0 &&
      parentStep[0].parallelSupplyChain1?.length == 0 &&
      parentStep[0].parallelSupplyChain2?.length == 0 &&
      parentStep[0].parallelSupplyChain3?.length == 0 &&
      parentStep[0].parallelSupplyChain4?.length == 0
    ) {
      this.stepIdToRemove = stepId;
      this.removeChain();
    } else {
      this.stepIdToRemove = stepId;
      this.showRemoveStepModal = true;
    }
  }

  private removeChain(): void {
    const indexOfStep = this.orderLineSteps.findIndex(
      (s) => s.stepId == this.stepIdToRemove
    );
    this.orderLineSteps.splice(indexOfStep, 1);
    this.setChainCount();
    this.stepIdToRemove = -1;
    this.showRemoveStepModal = false;
    // TTD-3886 changes added when new step is deleted with its parent step, it should allow again to add step
    if(this.isNewStepAdded){
      if(this.orderLineSteps.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
        this.isNewStepAdded = true;
      } else {
        this.isNewStepAdded = false;
      }
    }
  }

  private closeModal(stepIdText: string): void {
    this.stepIdToRemove = -1;
    this.showRemoveStepModal = false;
  }

  private showleftRightIcon(parentStepId: string, index: number): boolean {
    let show = false;
    const parentIndex = this.orderLineSteps.findIndex(
      (s) => s.stepId === Number(parentStepId)
    );
    switch (index) {
      case 0: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain0.length > 1
            ? true
            : false;
        break;
      }
      case 1: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain1.length > 1
            ? true
            : false;
        break;
      }
      case 2: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain2.length > 1
            ? true
            : false;
        break;
      }
      case 3: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain3.length > 1
            ? true
            : false;
        break;
      }
      case 4: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain4.length > 1
            ? true
            : false;
        break;
      }
    }
    return show;
  }

  private getParallelChain(
    index: number,
    parentStepId: string
  ): OrderLineStepParallelModel[] {
    const parentId =
      parentStepId !== null ? Number.parseInt(parentStepId) : null;
    let parallelChain: OrderLineStepParallelModel[] = [];
    if (index == 0) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain0!;
    } else if (index == 1) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain1!;
    } else if (index == 2) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain2!;
    } else if (index == 3) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain3!;
    } else {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain4!;
    }
    return parallelChain;
  }

  private addChain(stepIdText: string): void {
    if (this.isNewStepAdded) {
      return;
    }
    let count = 0;
    this.orderLineSteps.forEach((step) => {
      if (step.parallelSupplyChain0 && step.parallelSupplyChain0.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain1 && step.parallelSupplyChain1.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain2 && step.parallelSupplyChain2.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain3 && step.parallelSupplyChain3.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain4 && step.parallelSupplyChain4.length > 0) {
        count = count + 1;
      }
      if (count >= 5) {
        return;
      }
    });
    if (count >= 5) {
      this.showMaxParallelChainModal = true;
      return;
    }
    const stepId = Number.parseInt(stepIdText);
    const parentChain = this.orderLineSteps.filter(
      (s) => s.stepId == stepId
    )[0];
    const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(
      this.order,
      {
        stepId: 0,
        displayName: '',
        sequence: 1,
        productGroup: this.selectedProductGroup,
      },
      stepIdText
    );
    newStep.supplierStep = true;
    if (parentChain.parallelSupplyChain0?.length === 0) {
      parentChain.parallelSupplyChain0 = [newStep];
      this.setParallelChainStepDropdown(0, stepIdText);
    } else if (parentChain.parallelSupplyChain1?.length === 0) {
      parentChain.parallelSupplyChain1 = [newStep];
      this.setParallelChainStepDropdown(1, stepIdText);
    } else if (parentChain.parallelSupplyChain2?.length === 0) {
      parentChain.parallelSupplyChain2 = [newStep];
      this.setParallelChainStepDropdown(2, stepIdText);
    } else if (parentChain.parallelSupplyChain3?.length === 0) {
      parentChain.parallelSupplyChain3 = [newStep];
      this.setParallelChainStepDropdown(3, stepIdText);
    } else if (parentChain.parallelSupplyChain4?.length === 0) {
      parentChain.parallelSupplyChain4 = [newStep];
      this.setParallelChainStepDropdown(4, stepIdText);
    } else {
      this.showMaxParallelChainModal = true;
    }
    this.setChainCount();
    this.calculateDistance(false);
    this.isNewStepAdded = true;
  }

  private calculateDistance(onRender: boolean): void {
    setTimeout(
      async (app: any) => {
        const leftPoint = document
          .getElementById('step-list-custom-scroll')!
          .getBoundingClientRect();
        if (
          document.getElementById('leftest-leftplus') &&
          document.getElementById('leftest-leftplus')!.getBoundingClientRect()
            .x < leftPoint.x
        ) {
          const padding = app.paddingLeft + 200;
          app.paddingLeft = padding;
          const outerElement = document.getElementById(
            'step-list-custom-scroll'
          );
          outerElement!.style.paddingLeft = padding + 'px';
        } else if (
          document.getElementById('leftest-add') &&
          document.getElementById('leftest-add')!.getBoundingClientRect().x <
            leftPoint.x
        ) {
          const padding = app.paddingLeft + 210;
          app.paddingLeft = padding;
          const outerElement = document.getElementById(
            'step-list-custom-scroll'
          );
          outerElement!.style.paddingLeft = padding + 'px';
        }
      },
      250,
      this
    );
  }

  private setParallelChainStepDropdown(
    indexOfChain: number,
    parentStepIdText: string
  ): void {
    let parentStepIndex = 0;
    switch (indexOfChain) {
      case 0:
        this.parallelChain0DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain0?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain0DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        this.parallelChain0DropdownSteps.sort(function(type1, type2){
          if(type1.text < type2.text) { return -1; }
          if(type1.text > type2.text) { return 1; }
          return 0;
        });
        break;
      case 1:
        this.parallelChain1DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain1?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain1DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        this.parallelChain1DropdownSteps.sort(function(type1, type2){
          if(type1.text < type2.text) { return -1; }
          if(type1.text > type2.text) { return 1; }
          return 0;
        });
        break;
      case 2:
        this.parallelChain2DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain2?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain2DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        this.parallelChain2DropdownSteps.sort(function(type1, type2){
          if(type1.text < type2.text) { return -1; }
          if(type1.text > type2.text) { return 1; }
          return 0;
        });
        break;
      case 3:
        this.parallelChain3DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain3?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain3DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        this.parallelChain3DropdownSteps.sort(function(type1, type2){
          if(type1.text < type2.text) { return -1; }
          if(type1.text > type2.text) { return 1; }
          return 0;
        });
        break;
      case 4:
        this.parallelChain4DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain4?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain4DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        this.parallelChain4DropdownSteps.sort(function(type1, type2){
          if(type1.text < type2.text) { return -1; }
          if(type1.text > type2.text) { return 1; }
          return 0;
        });
        break;
    }
  }

  private getOrderLine(): void {
    this.orderRefId = this.orderLine[0].orderRefId;
    const user = this.$store.getters.user as UserModel;
    this.orderLine.forEach((order, index) => {
      if (index === 0) {
        this.order.id = order.id;
        this.order.orderId = order.orderId;
        this.order.lineID = order.orderLines[0].lineID;
        this.order.orderNumber = order.orderNumber;
        this.order.expectedTimeOfDelivery =
          DateTimeHelper.convertDateDesiredFormat(
            order.expectedTimeOfDelivery,
            'D MMM YYYY'
          );
        this.order.styleName = order.styleName;
        this.order.styleNumber = order.styleNumber;
        this.order.companyName = order.fromCompanyUser.name;
        this.order.toCompanyId = order.toCompanyId;
        this.order.toCompanyName = order.toCompanyName;
        this.selectedProductGroup = order.productGroup;
        this.order.colourway =
          order.orderLines[0].colourway == undefined ||
          order.orderLines[0].colourway.trim() == ''
            ? '-'
            : order.orderLines[0].colourway;
        // TTD-2630 Sprint 18
        this.order.delegateCompanyID = order.delegateCompanyID;
        this.order.delegateCompanyUser = order.delegateCompanyUser;
        this.order.delegatedAt = order.delegatedAt;
      } else {
        this.order.orderNumber += ', ' + order.orderNumber;
        this.order.expectedTimeOfDelivery +=
          ', ' +
          DateTimeHelper.convertDateDesiredFormat(
            order.expectedTimeOfDelivery,
            'D MMM YYYY'
          );
        this.order.styleName +=
          this.orderLine[index - 1].styleName !== ''
            ? ', ' + order.styleName
            : '';
        this.order.styleNumber += ', ' + order.styleNumber;
        this.order.colourway +=
          order.orderLines[0].colourway == undefined ||
          order.orderLines[0].colourway.trim() == ''
            ? ', -'
            : ', ' + order.orderLines[0].colourway;
            // TTD-2630 Sprint 18
            this.order.delegateCompanyID = order.delegateCompanyID;
            this.order.delegateCompanyUser = order.delegateCompanyUser;
            this.order.delegatedAt = order.delegatedAt;
      }
      this.delegatedPartnerName = order.delegateCompanyUser.companyName;
      // TTD-2630 Sprint 18, added && condition
      if(this.delegatedPartnerName!=='' && order.delegateCompanyID !== user.companyId){
        this.wantToDelegate = true;
      }
    });
    const orderNumber = this.order.orderNumber?.split(',');
    let colourWay = this.order.colourway?.split(',');
    if (colourWay && orderNumber) {
      const colour = colourWay;
      if (
        (colourWay.every((val) => val.trim() === '-') &&
          orderNumber.every((val) => val.trim() === orderNumber[0])) ||
        colourWay.every((val) => val.trim() === '-')
      ) {
        this.order.colourway = '-';
      } else if (
        (colourWay.every((val) => val.trim() === colour[0]) &&
          orderNumber.every((val) => val.trim() === orderNumber[0])) ||
        colourWay.every((val) => val.trim() === colour[0])
      ) {
        this.order.colourway = colour[0];
      } else if (this.order.colourway?.trim().includes('-')) {
        colourWay = colourWay.filter((val) => val.trim() !== '-');
        this.order.colourway = colourWay.toString();
      }
    }
  }

  private get createDisabled(): boolean {
    if (this.orderLineSteps.length > 0 && this.selectedStep === 0) {
      this.selectedStep = this.orderLineSteps[0].stepId;
      this.stepChanged();
    }
    this.isEmptyStepName = false;
    let isDisable = false;
    isDisable =
      this.selectedProductGroup == null ||
      this.selectedProductGroup === 0 ||
      this.orderLineSteps.some((o) => o.toCompanyId === Guid.Empty);
      if(this.orderLineSteps.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
        this.isEmptyStepName = true;
      }
      this.orderLineSteps.forEach((o) => {
        if (o.parallelSupplyChain0.length > 0) {
          if(!isDisable){
            isDisable = o.parallelSupplyChain0.some((o) => o.toCompanyId === Guid.Empty);
          }
          if(o.parallelSupplyChain0.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain1.length > 0) {
          if(!isDisable){
            isDisable = o.parallelSupplyChain1.some(
              (o) => o.toCompanyId === Guid.Empty
            );
          }
          if(o.parallelSupplyChain1.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain2.length > 0) {
          if(!isDisable){
            isDisable = o.parallelSupplyChain2.some(
              (o) => o.toCompanyId === Guid.Empty
            );
          }
          if(o.parallelSupplyChain2.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain3.length > 0) {
          if(!isDisable){
            isDisable = o.parallelSupplyChain3.some(
              (o) => o.toCompanyId === Guid.Empty
            );
          }
          if(o.parallelSupplyChain3.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain4.length > 0) {
          if(!isDisable){
            isDisable = o.parallelSupplyChain4.some(
              (o) => o.toCompanyId === Guid.Empty
            );
          }
          if(o.parallelSupplyChain4.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
      });
    return isDisable;
  }

  private get isDeleteDisabled(): boolean {
    return this.deleteDisabled;
  }

  private get assignDisabled(): boolean {
    return this.selectedProductGroup == null || this.selectedProductGroup === 0;
  }

  private get assignScpDisabled(): boolean {
    return (
      this.selectedProductGroup == null ||
      this.selectedProductGroup === 0 ||
      this.selectedStep <= 0
    );
  }

  private get steps(): DropdownModel[] {
    return this.orderLineSteps
      .filter((s) => s.stepId !== 0)
      .map((s) => ({ value: `${s.stepId}`, text: s.displayName }));
  }

  private get dropdownSteps(): DropdownModel[] {
    const result: DropdownModel[] = [];
    this.allSteps.forEach((step) => {
      const index = this.orderLineSteps.findIndex(
        (sp) => sp.stepId === step.stepId
      );
      if (index === -1) {
        result.push(new DropdownModel(`${step.stepId}`, step.displayName));
      }
    });
    result.sort(function(type1, type2){
      if(type1.text < type2.text) { return -1; }
      if(type1.text > type2.text) { return 1; }
      return 0;
    });
    return result;
  }

  private get supplyChainPartners(): InviteDropdownModel[] {
    const user = this.$store.getters.user as UserModel;
    let allSuppliers: OrderCompanyModel[] = this.allSupplyChainPartners;
    if(this.isDelegate){
      allSuppliers = allSuppliers.filter(s => s.id !== user.companyId);
    }
    return allSuppliers.map((s) => ({
      value: s.id,
      text: s.isCompliant
        ? s.id === user.companyId
          ? `${s.companyName} (me)`
          : `${s.companyName} (${s.country})`
        : `${s.companyName} (${this.$t('pages.home.pending_invite')})`,
        selected: false
    }));
  }

  private get emptyGuid(): string {
    return Guid.Empty;
  }

  // Display step name based on the country language
  private displayName(stepId: number): string {
    return this.$t(`enums.steps.${stepId}`).toString();
  }

  private get selectedScpName(): string {
    if (this.selectedScp == null || this.selectedScp === Guid.Empty) {
      return '';
    }
    return this.supplyChainPartners.filter(
      (s) => s.value === this.selectedScp
    )[0] !== undefined
      ? this.supplyChainPartners.filter((s) => s.value === this.selectedScp)[0]
          .text
      : '';
  }

  private get clientCompanyId(): string {
    return this.clientId;
  }

  private async getSupplyChainPartners(): Promise<void> {
    if (
      this.suppliers != null &&
      this.suppliers.length > 0 &&
      !this.supplierChanged
    ) {
      this.allSupplyChainPartners = this.suppliers;
    } else {
      const user = store.getters.user as UserModel;
      this.allSupplyChainPartners =
      await this.supplierService.getSuppliersListAsync(user.companyId);
    }
  }

  private async getSupplyChainSteps(): Promise<void> {
    this.allSteps = StepsHelper.getStepsByProductGroup(
      this.selectedProductGroup
    );
  }

  private async getOrderLineSteps(): Promise<void> {
    if (this.orderLineStep.length > 0) {
      this.clientId = this.orderLineStep[0].fromCompanyId;
    }
  }

  private async getSupplyChainStepsForProductGroup(
    productGroup: number
  ): Promise<void> {
    this.stepsForProductGroup = this.allSteps.filter(
      (s) => s.showDefault === true
    );
    this.stepsForProductGroup.sort((step1, step2) => {
      return step1.sequence - step2.sequence;
    });
    this.orderLineSteps = [];
    if (
      this.draftOrderLineSteps !== undefined &&
      this.draftOrderLineSteps.length > 0
    ) {
      this.orderLineSteps = this.draftOrderLineSteps;
    } else {
      if (this.orderLineStep.length > 0) {
        if (this.lineSteps !== undefined && this.lineSteps.length > 0) {
          this.orderLineSteps = this.lineSteps;
          this.customisedCount = this.orderLineSteps.filter(
            (s) =>
              (s.stepState === StepState.CREATED_BY_CLIENT ||
                s.stepState === StepState.UPDATED_BY_CLIENT ||
                s.stepState === StepState.CREATED_BY_AGENT ||
                s.stepState === StepState.UPDATED_BY_AGENT ||
                s.stepState === StepState.NONE) &&
              s.toCompanyId !== Guid.Empty &&
              s.supplierStep === false
          ).length;
        } else {
          const steps: OrderLineStepModel[] = [];
          const step:OrderLineStepModel[] = cloneDeep(this.orderLineStep);
          step.filter((s) => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE)
            .forEach((step) => {
              if (steps.length > 0) {
                if (steps.findIndex((s) => s.stepId === step.stepId) === -1) {
                  step.toCompanyName =
                    step.toCompanyId === Guid.Empty ? '' : (this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName);
                  step.supplierStep = false;
                  if(!step.footprintRefNo){
                    step.footprintRefNo = '';
                  }
                  steps.push(step);
                }
              } else {
                step.toCompanyName =
                  step.toCompanyId === Guid.Empty ? '' : (this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName);
                step.supplierStep = false;
                if(!step.footprintRefNo){
                  step.footprintRefNo = '';
                }
                steps.push(step);
              }
            });
          this.orderLineSteps = steps;
          this.customisedCount = this.orderLineSteps.filter(
            (s) =>
              (s.stepState === StepState.CREATED_BY_CLIENT ||
                s.stepState === StepState.UPDATED_BY_CLIENT ||
                s.stepState === StepState.NONE) &&
              s.toCompanyId !== Guid.Empty &&
              s.supplierStep === false
          ).length;
        }
        this.orderLineSteps.forEach((s) => {
          if (s.parallelSupplyChain0.length > 0) {
            s.parallelSupplyChain0.forEach((p) => {
              if (p.toCompanyId != Guid.Empty && p.toCompanyName != '') {
                p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
                p.supplierStep = false;
              }
              p.parentStepId = s.stepId.toString();
              if(!p.footprintRefNo){
                p.footprintRefNo = ''
              }
            });
          }
          if (s.parallelSupplyChain1.length > 0) {
            s.parallelSupplyChain1.forEach((p) => {
              if (p.toCompanyId != Guid.Empty && p.toCompanyName != '') {
                p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
                p.supplierStep = false;
              }
              p.parentStepId = s.stepId.toString();
              if(!p.footprintRefNo){
                p.footprintRefNo = ''
              }
            });
          }
          if (s.parallelSupplyChain2.length > 0) {
            s.parallelSupplyChain2.forEach((p) => {
              if (p.toCompanyId != Guid.Empty && p.toCompanyName != '') {
                p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
                p.supplierStep = false;
              }
              p.parentStepId = s.stepId.toString();
              if(!p.footprintRefNo){
                p.footprintRefNo = ''
              }
            });
          }
          if (s.parallelSupplyChain3.length > 0) {
            s.parallelSupplyChain3.forEach((p) => {
              if (p.toCompanyId != Guid.Empty && p.toCompanyName != '') {
                p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
                p.supplierStep = false;
              }
              p.parentStepId = s.stepId.toString();
              if(!p.footprintRefNo){
                p.footprintRefNo = ''
              }
            });
          }
          if (s.parallelSupplyChain4.length > 0) {
            s.parallelSupplyChain4.forEach((p) => {
              if (p.toCompanyId != Guid.Empty && p.toCompanyName != '') {
                p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
                p.supplierStep = false;
              }
              p.parentStepId = s.stepId.toString();
              if(!p.footprintRefNo){
                p.footprintRefNo = ''
              }
            });
          }
        });
        this.orderLineSteps.sort((step1, step2) => {
          return step1.sequence - step2.sequence;
        });
      } else {
        this.stepsForProductGroup.forEach((step, index) => {
          this.defaultOrderLine.toCompanyId = Guid.Empty;
          this.defaultOrderLine.toCompanyName = '';
          this.defaultOrderLine.CompanyId = Guid.Empty;
          step.displayName =
            step.displayName.charAt(0).toUpperCase() +
            step.displayName.slice(1);
          this.orderLineSteps.push(
            new OrderLineStepModel(this.defaultOrderLine, step)
          );
          if (step.showDefaultParallelChain) {
            step.parallelChain!.forEach((defaultchild, defaultchildIndex) => {
              const defaultStep = {
                displayName: i18n.t(`enums.steps.${defaultchild}`).toString(),
                stepId: defaultchild,
                sequence: 1,
                productGroup: step.productGroup,
                showDefault: true,
              };
              const newStep: OrderLineStepParallelModel =
                new OrderLineStepParallelModel(
                  this.order,
                  defaultStep,
                  step.stepId.toString()
                );
              if (defaultchildIndex == 0) {
                this.orderLineSteps[index].parallelSupplyChain0 = [newStep];
              }
              if (defaultchildIndex == 1) {
                this.orderLineSteps[index].parallelSupplyChain1 = [newStep];
              }
            });
          }
        });
        if (this.orderLineStep.length > 0) {
          this.assignClientSteps();
        }
      }
    }
  }

  private changeStyleForParallel(
    stepId: number,
    parentStepId: number,
    indexOfChain: number
  ): void {
    this.isParallel = true;
    if (
      this.getParallelChain(indexOfChain, parentStepId.toString()).filter(
        (c) => c.stepId == stepId
      )[0].isSelected === true
    ) {
      this.getParallelChain(indexOfChain, parentStepId.toString()).filter(
        (c) => c.stepId == stepId
      )[0].isSelected = false;
    } else {
      this.getParallelChain(indexOfChain, parentStepId.toString()).filter(
        (c) => c.stepId == stepId
      )[0].isSelected = true;
    }
    const step = this.getParallelChain(
      indexOfChain,
      parentStepId.toString()
    ).filter((c) => c.stepId == stepId)[0];
    const index = this.getParallelChain(
      indexOfChain,
      parentStepId.toString()
    ).findIndex((s) => s.stepId == stepId);
    this.getParallelChain(indexOfChain, parentStepId.toString()).splice(
      index,
      1
    );
    this.getParallelChain(indexOfChain, parentStepId.toString()).splice(
      index,
      0,
      step
    );
  }

  private changeStyle(stepId: string): void {
    this.isParallel = false;
    if (
      this.orderLineSteps.filter((s) => s.stepId === Number.parseInt(stepId))[0]
        .isSelected === true
    ) {
      this.orderLineSteps.filter(
        (s) => s.stepId === Number.parseInt(stepId)
      )[0].isSelected = false;
    } else {
      this.orderLineSteps.filter(
        (s) => s.stepId === Number.parseInt(stepId)
      )[0].isSelected = true;
    }
    const step = this.orderLineSteps.filter(
      (s) => s.stepId === Number.parseInt(stepId)
    )[0];
    const index = this.orderLineSteps.findIndex(
      (s) => s.stepId == Number.parseInt(stepId)
    );
    this.orderLineSteps.splice(index, 1);
    this.orderLineSteps.splice(index, 0, step);
  }

  private getStatusForParallelStep(
    stepId: number,
    parentStepId: string,
    indexOfChain: number
  ): string {
    const parentId =
      parentStepId !== null ? Number.parseInt(parentStepId) : null;
    const supplierAssigned =
      this.getParallelChain(indexOfChain, parentStepId).filter(
        (c) => c.stepId == stepId
      )[0].toCompanyId !== Guid.Empty;
    return supplierAssigned ? 'green' : 'red';
  }

  private assignClientSteps(): void {
    const steps:OrderLineStepModel[] = cloneDeep(this.orderLineStep);
    steps.forEach((assignedStep) => {
      const stepIndex = this.orderLineSteps.findIndex(
        (s) => s.stepId === assignedStep.stepId
      );
      if (
        assignedStep.toCompanyId !== Guid.Empty &&
        (assignedStep.toCompanyName === null ||
          assignedStep.toCompanyName === '')
      ) {
        this.orderLineSteps[stepIndex].id = Guid.Empty;
        this.orderLineSteps[stepIndex].toCompanyId = Guid.Empty;
        this.orderLineSteps[stepIndex].toCompanyName = '';
        this.orderLineSteps[stepIndex].fromCompanyId = Guid.Empty;
        if(!this.orderLineSteps[stepIndex].footprintRefNo){
          this.orderLineSteps[stepIndex].footprintRefNo = '';
        }
      } else {
        this.orderLineSteps[stepIndex].id = assignedStep.id;
        this.orderLineSteps[stepIndex].toCompanyId = assignedStep.toCompanyId;
        this.orderLineSteps[stepIndex].toCompanyName =
          assignedStep.toCompanyName;
        this.orderLineSteps[stepIndex].fromCompanyId =
          assignedStep.fromCompanyId;
          this.orderLineSteps[stepIndex].stepState =
          assignedStep.stepState;
        this.orderLineSteps[stepIndex].supplierStep = false;
        if(!this.orderLineSteps[stepIndex].footprintRefNo){
          this.orderLineSteps[stepIndex].footprintRefNo = '';
        }
      }
    });
  }

  private getStatusForStep(stepId: number): string {
    const supplierAssigned =
      this.orderLineSteps.filter((o) => o.stepId === stepId)[0].toCompanyId !==
      Guid.Empty;
    return supplierAssigned ? 'green' : 'red';
  }

  private setName(stepIdText: string): void {
    const stepId = Number.parseInt(stepIdText);
    const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
    this.orderLineSteps.filter((sp) => sp.stepId === stepId)[0].displayName =
      item.displayName;
    this.assignNameToOtherNewStep = false;
    this.addingInProgress = false;
    if(this.isNewStepAdded){
      if(this.orderLineSteps.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
        this.isNewStepAdded = true;
      } else {
        this.isNewStepAdded = false;
      }
    }
  }

  private setNameForParallel(
    stepIdText: string,
    indexOfChain: number,
    parentStepId: string
  ): void {
    const stepId = Number.parseInt(stepIdText);
    const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : 0;
    const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
    this.getParallelChain(indexOfChain, parentStepId).filter(
      (c) => c.stepId === stepId
    )[0].displayName = item.displayName;
    this.isNewStepAdded = false;
  }

  private enableDelete(event: any): void {
    this.isDragging = true;
    if(event.data !== null && event.data !== undefined){
      const step = event.data as SupplyChainStepModel;
      this.draggedStep = step.stepId;
    }
    this.deleteDisabled = false;
    this.showHover = false;
  }

  private disableDelete(): void {
    this.deleteDisabled = true;
    this.draggedStep = -1;
    if (this.isBulk === true) {
      this.showDraft = true;
      this.showBack = false;
    }
    this.setChainCount();
    this.showHover = true;
    this.calculateDistance(false);
  }

  private add(stepIdText: string): void {
    const stepId = Number.parseInt(stepIdText);
    const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
    this.addAtIndex(index + 1);
  }

  private addAtIndex(index: number): void {
    if (this.orderLineSteps.some((a) => a.stepId === 0)) {
      this.assignNameToOtherNewStep = true;
      return;
    } else if (this.dropdownSteps.length <= 1) {
      return;
    }
    this.addingInProgress = true;
    const newStep: OrderLineStepModel = new OrderLineStepModel(
      this.defaultOrderLine,
      {
        stepId: 0,
        displayName: '',
        sequence: index,
        productGroup: this.selectedProductGroup,
      }
    );
    newStep.supplierStep = true;
    this.orderLineSteps.splice(index, 0, newStep);
    if (this.isBulk === true) {
      this.showDraft = true;
      this.showBack = false;
    }
  }

  private addInParallel(
    stepIdText: string,
    parentStepIdText: string,
    indexOfChain: number
  ): void {
    if (!this.isNewStepAdded) {
      const stepId = Number.parseInt(stepIdText);
      const ParentStepId = Number.parseInt(parentStepIdText);
      const index = this.getParallelChain(
        indexOfChain,
        parentStepIdText
      ).findIndex((s) => s.stepId === stepId);
      const sequence = this.getParallelChain(indexOfChain, parentStepIdText).filter(i => i.stepId === stepId)[0].sequence;
      if (sequence < this.getParallelChain(indexOfChain, parentStepIdText).length) {
        this.getParallelChain(indexOfChain, parentStepIdText).filter(p => p.sequence > sequence).forEach(c => {
          c.sequence = c.sequence+1;
        })
      }
      if (stepIdText !== null) {
        const stepId = Number.parseInt(stepIdText);
        const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
        const ParentStepId = Number.parseInt(parentStepIdText);
        const newStep: OrderLineStepParallelModel =
          new OrderLineStepParallelModel(
            this.order,
            {
              stepId: 0,
              displayName: '',
              sequence: sequence + 1,
              productGroup: this.selectedProductGroup,
            },
            parentStepIdText
          );
        newStep.supplierStep = true;
        this.getParallelChain(indexOfChain, parentStepIdText).splice(
          index,
          0,
          newStep
        );
        this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
        this.isNewStepAdded = true;
        this.calculateDistance(false);
      }
    }
  }

  private addInParallelAtIndex(
    index: number,
    stepIdText: string | null,
    parentStepIdText: string,
    indexOfChain: number
  ): void {
    if (!this.isNewStepAdded) {
      if (stepIdText !== null) {
        const stepId = Number.parseInt(stepIdText);
        const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
        const ParentStepId = Number.parseInt(parentStepIdText);
        const newStep: OrderLineStepParallelModel =
          new OrderLineStepParallelModel(
            this.order,
            {
              stepId: 0,
              displayName: '',
              sequence: 1,
              productGroup: this.selectedProductGroup,
            },
            parentStepIdText
          );
        newStep.supplierStep = true;
        this.getParallelChain(indexOfChain, parentStepIdText).forEach(p => {
          p.sequence = p.sequence+1;
        })
        this.getParallelChain(indexOfChain, parentStepIdText).splice(
          index + 1,
          0,
          newStep
        );
        this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
        this.isNewStepAdded = true;
        this.calculateDistance(false);
      }
    }
  }

  private openSearchSelectHandlerParallel(
    stepId: number,
    parentStepId: number,
    indexOfChain: number,
    toCompanyId: string
  ): void {
    this.toCompanyId = toCompanyId;
    if (stepId !== -1) {
      const index = this.getParallelChain(
        indexOfChain,
        parentStepId.toString()
      ).findIndex((t) => t.stepId === stepId);
      const steps = this.getParallelChain(
        indexOfChain,
        parentStepId.toString()
      );
      if ((steps[index].supplierStep === false && (steps[index].stepState < StepState.CREATED_BY_SUPPLIER || steps[index]!.stepState > StepState.UPDATED_BY_SUPPLIER)) && steps[index].toCompanyId !== Guid.Empty) {
        return;
      }
      const user = store.getters.user as UserModel;
      if ((steps[index].stepState===StepState.CREATED_BY_SUPPLIER || steps[index].stepState===StepState.UPDATED_BY_SUPPLIER || steps[index].stepState===StepState.CREATED_BY_CLIENT || steps[index].stepState===StepState.UPDATED_BY_CLIENT || steps[index].stepState===StepState.CREATED_BY_AGENT || steps[index].stepState===StepState.UPDATED_BY_AGENT || steps[index].stepState===0) && steps[index].toCompanyId !== Guid.Empty && user.companyId === this.order.delegateCompanyID) {
        return;
      }
      this.indexOfChain = indexOfChain;
      this.indexOfChainStep = index;
      this.selectedParallelStep = stepId;
      this.parentStepId = parentStepId;
      this.showSearch = true;
      this.showSupplierForParallel = true;

      this.changeStyleForParallel(stepId, parentStepId, indexOfChain);
      if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].footprintRefNo !==''){
        this.referenceNumber = this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
          this.indexOfChainStep
        ].footprintRefNo
      }
      return;
    }
    this.showTier1 = true;
  }

  private setSupplierHandlerParallel(value: string, refNum: string): void {
    const user = store.getters.user as UserModel;
    if (
      this.orderLineSteps.filter((s) => s.stepId == this.parentStepId)[0]
        .toCompanyName === ''
    ) {
      this.customisedCount++;
    }
    const selectSCP = this.allSupplyChainPartners.filter(
      (s) => s.id === value
    )[0];
    if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].id === undefined || this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].id === "" || this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].id === Guid.Empty) {
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].id = uuidv4();
    }
    (this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].fromCompanyId = this.company.companyId),
      (this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].toCompanyName = selectSCP.companyName);
    this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].toCompanyId = selectSCP.id;
    if (this.order.delegateCompanyID === user.companyId) {
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].stepState = StepState.UPDATED_BY_DELEGATEPARTNER;
    } else {
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].stepState = StepState.UPDATED_BY_SUPPLIER;
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].supplierStep = true;
    }
    if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefNo!==refNum){
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefNo = refNum;
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefNoUpdatedBy = user.companyId;
      if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefID===Guid.Empty){
        this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefID = uuidv4();
      }
    }
    this.showTier1 = false;
    this.showSearch = false;
    this.showSupplierForParallel = false;
    this.changeStyleForParallel(
      this.selectedParallelStep,
      this.parentStepId,
      this.indexOfChain
    );
  }

  private closeSearchHandlerParallel(): void {
    this.showSupplierForParallel = false;
    this.showSupplier = false;
    this.changeStyleForParallel(
      this.selectedParallelStep,
      this.parentStepId,
      this.indexOfChain
    );
  }

  private setInvalid(): void {
    this.assignNameToOtherNewStep = true;
  }

  private deleteStep(event: any): void {
    if (this.orderLineSteps.length <= 1 || this.deleteDisabled) {
      return;
    }
    const step = event.data as OrderLineStepModel;
    if (step.supplierStep === false && step.toCompanyId !== Guid.Empty) {
      return;
    }
    this.selectedScp = Guid.Empty;
    this.selectedStep = 0;
    const index = this.orderLineSteps.findIndex(
      (s) => s.stepId === step.stepId
    );
    this.orderLineSteps.splice(index, 1);
    if (this.isBulk === true) {
      this.showDraft = true;
      this.showBack = false;
    }
  }

  private stepChanged(): void {
    if (this.selectedStep <= 0) {
      return;
    }
    const orderLineStep = this.orderLineSteps.filter(
      (o) => o.stepId === this.selectedStep
    )[0];
    this.selectedScp = orderLineStep.toCompanyId;
  }

  private setSupplierIdForStep(scpId: string): void {
    if (this.selectedStep <= 0 || this.selectedScp === Guid.Empty) {
      return;
    }
    const orderLineStep = this.orderLineSteps.filter(
      (o) => o.stepId === this.selectedStep
    )[0];
    orderLineStep.toCompanyId = scpId;
  }

  private swipeLeft(): void {
    const contentToScroll = this.$refs.scrollContent as Vue;
    contentToScroll.$el.scrollLeft -= 200;
  }

  private swipeRight(): void {
    const contentToScroll = this.$refs.scrollContent as Vue;
    contentToScroll.$el.scrollLeft += 200;
  }

  private closeSearchHandler(): void {
    this.showSearch = false;
    this.showSupplier = false;
    this.isDelegate = false;
    if (!this.isParallel) {
      this.changeStyle(this.selectedStep.toString());
    }
  }

  private openSearchSelectHandler(stepId: number, toCompanyId:string): void {
    this.toCompanyId = toCompanyId;
    const step = this.orderLineSteps.find((s) => s.stepId === stepId);
    const user = store.getters.user as UserModel;
    if ((step?.supplierStep === false && (step?.stepState < StepState.CREATED_BY_SUPPLIER || step?.stepState > StepState.UPDATED_BY_SUPPLIER)) && step?.toCompanyId !== Guid.Empty  && user.companyId !== this.order.delegateCompanyID) {
      return;
    }
    if ((step?.stepState===StepState.CREATED_BY_SUPPLIER || step?.stepState===StepState.UPDATED_BY_SUPPLIER || step?.stepState===StepState.CREATED_BY_CLIENT || step?.stepState===StepState.UPDATED_BY_CLIENT || step?.stepState===StepState.CREATED_BY_AGENT || step?.stepState===StepState.UPDATED_BY_AGENT || step?.stepState===0) && step?.toCompanyId !== Guid.Empty && user.companyId === this.order.delegateCompanyID) {
      return;
    }
    const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
    this.stepIndex = index;
    if (this.assignScpDisabled) {
      return;
    }
    this.showSearch = true;
    this.selectedStep = stepId;
    this.changeStyle(stepId.toString());
    if(this.orderLineSteps[index].footprintRefNo!==''){
      this.referenceNumber = this.orderLineSteps[index].footprintRefNo;
    }
  }

  private async invitationSendHandler(): Promise<void> {
    this.supplierChanged = true;
    await this.getSupplyChainPartners();
  }

  private setSupplierHandler(value: string, refNum: string): void {
    //commenting these changes not required now sprint-18
    // if(this.orderLineStep.length >0 && this.defaultSteps.length == 0){
    //   this.defaultSteps = lodash.cloneDeep(this.orderLineStep);
    // }
    const selectSCP = this.allSupplyChainPartners.filter(
      (s) => s.id === value
    )[0];
    const history: StepHistory = {};
      (history.sequence = this.orderLineSteps[this.stepIndex].sequence),
      (history.description = '');
    history.stepId = this.orderLineSteps[this.stepIndex].stepId;
    (history.displayName = this.stepNames[history.stepId]),
    history.createdByCompanyId =
      this.orderLineSteps[this.stepIndex].fromCompanyId === Guid.Empty
        ? this.company.companyId
        : this.orderLineSteps[this.stepIndex].fromCompanyId;
    history.createdByCompanyName =
      this.orderLineSteps[this.stepIndex].fromCompanyId === Guid.Empty
        ? this.company.companyName
        : '';
    history.editedByCompanyId = this.company.companyId;
    history.editedByCompanyName = this.company.companyName;
    history.updateAt = new Date().toISOString();
    this.orderLineSteps[this.stepIndex].stepHistory.push(history);
    this.orderLineSteps[this.stepIndex].fromCompanyId = this.company.companyId;
    this.orderLineSteps[this.stepIndex].toCompanyName = selectSCP.companyName;
    this.orderLineSteps[this.stepIndex].toCompanyId = selectSCP.id;
    const user = store.getters.user as UserModel;
    if (this.order.delegateCompanyID === user.companyId) {
      this.orderLineSteps[this.stepIndex].supplierStep = false;
      this.orderLineSteps[this.stepIndex].stepState = StepState.UPDATED_BY_DELEGATEPARTNER;
    } else {
      this.orderLineSteps[this.stepIndex].supplierStep = true;
      this.orderLineSteps[this.stepIndex].stepState = StepState.UPDATED_BY_SUPPLIER;
    }
    if (this.isBulk === true) {
      this.showDraft = true;
      this.showBack = false;
    }
    this.showSearch = false;
    // TTD-3619
    if(this.orderLineSteps[this.stepIndex].footprintRefNo!==refNum){
      this.orderLineSteps[this.stepIndex].footprintRefNo = refNum;
      this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
      if(this.orderLineSteps[this.stepIndex].footprintRefID === Guid.Empty){
        this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
      }
    }
    this.changeStyle(this.selectedStep.toString());
  }

  private async productGroupChanged(): Promise<void> {
    try {
      this.isLoading = true;
      this.selectedScp = Guid.Empty;
      this.selectedStep = 0;
      await this.getSupplyChainStepsForProductGroup(this.selectedProductGroup);
    } finally {
      this.isLoading = false;
    }
  }

  private getStepId(orderId: string, stepId: number): string {
    const stepsArr = this.clientSteps;
    const steps = stepsArr.filter((s) => s.orderId === orderId);
    const id = steps.filter((s) => s.stepId === stepId)[0].id;
    return id;
  }

  private saveDraft(): void {
    this.orderLineSteps.forEach((step, index) => {
      step.sequence = index + 1;
    });
    this.back(this.orderLineSteps);
  }

  private createSupplyChainClick(): void {
    const user = store.getters.user as UserModel;
    if((this.delegatedPartnerName !== '' && this.order.delegateCompanyID !== user.companyId) && !this.createDisabled){
      this.isShowRemoveDelegator = true
    }else{
      this.processStepMissing = [];
      if(this.delegatedPartnerName === '' || (this.delegatedPartnerName !=='' && this.order.delegateCompanyID === user.companyId)){
        if(this.mandatoryStepProcesses.length > 0){
          const orderID = this.orderLine[0].orderId;
          const steps = this.orderLineSteps.filter(s => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER);
          this.mandatoryProcesses.forEach(process => {
            const stepIDs = process.processSteps.map(ps => ps.stepID);
            process.stepsExistCount = 0;
            process.processSteps.forEach(ps => {
              process.stepsExistCount = process.stepsExistCount + steps.filter(s => s.stepId === ps.stepID).length;
              steps.forEach(step => {
                if(step.parallelSupplyChain0.length > 0){
                  process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain0.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                  if(step.parallelSupplyChain1.length > 0){
                    process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain1.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                    if(step.parallelSupplyChain2.length > 0){
                      process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain2.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                      if(step.parallelSupplyChain3.length > 0){
                        process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain3.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                        if(step.parallelSupplyChain4.length > 0){
                          process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain4.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                        }
                      }
                    }
                  }
                }
              })
            })
            if(process.stepsExistCount === 0){
              this.processStepMissing.push(process.processID);
            }
          })
        }
        if(this.processStepMissing.length > 0){
          this.showMandatoryStepModalPopup('MISSING');
          return;
        }
      }
      const confirmationText = this.$t(
        'pages.home.confirm_create_supply_chain'
      ).toString();
      this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false})
        .then(() => {
          //adding loader on confirm button
          this.isCreating = true;
          if(this.delegatedPartnerName !=='' && this.order.delegateCompanyID !== user.companyId){
            this.isDelegatedToCp = true;
          }else{
            this.isDelegatedToCp = false;
          }
          this.confirmOrderLine(this.orderLine, this.orderLineSteps, this.isDelegatedToCp, this.selectedDelegateObj, this.isDelegatedPartnerRemoved);
        })
        .catch((onrejected) => {});
    }
  }

  private checkCount(str: string): boolean {
    if (str.indexOf(', ') !== -1) {
      return false;
    }
    return true;
  }

  private checkStepsCustomised(){
    if(this.orderLineSteps.findIndex(s => s.stepState === StepState.CREATED_BY_DELEGATEPARTNER || s.stepState === StepState.UPDATED_BY_DELEGATEPARTNER ||
        s.parallelSupplyChain0.findIndex(pc0=> pc0.stepState === StepState.CREATED_BY_DELEGATEPARTNER || pc0.stepState === StepState.UPDATED_BY_DELEGATEPARTNER) !== -1 ||
        s.parallelSupplyChain1.findIndex(pc1=> pc1.stepState === StepState.CREATED_BY_DELEGATEPARTNER || pc1.stepState === StepState.UPDATED_BY_DELEGATEPARTNER) !== -1 ||
        s.parallelSupplyChain2.findIndex(pc2=> pc2.stepState === StepState.CREATED_BY_DELEGATEPARTNER || pc2.stepState === StepState.UPDATED_BY_DELEGATEPARTNER) !== -1 ||
        s.parallelSupplyChain3.findIndex(pc3=> pc3.stepState === StepState.CREATED_BY_DELEGATEPARTNER || pc3.stepState === StepState.UPDATED_BY_DELEGATEPARTNER) !== -1 ||
        s.parallelSupplyChain4.findIndex(pc4=> pc4.stepState === StepState.CREATED_BY_DELEGATEPARTNER || pc4.stepState === StepState.UPDATED_BY_DELEGATEPARTNER) !== -1) !== -1){
        this.isCustomisedByDelegator = true;
    }else{
        this.isCustomisedByDelegator = false;
    }
  }

  private showBlockUpdate(step: OrderLineStepModel): boolean {
    const user = this.$store.getters.user as UserModel;
    if ((step.stepState===StepState.CREATED_BY_SUPPLIER || step.stepState===StepState.UPDATED_BY_SUPPLIER || step.stepState===StepState.CREATED_BY_CLIENT || step.stepState===StepState.UPDATED_BY_CLIENT || step.stepState===StepState.CREATED_BY_AGENT || step.stepState===StepState.UPDATED_BY_AGENT || step.stepState===0) && step.toCompanyId !== Guid.Empty && user.companyId === this.order.delegateCompanyID) {
      return true;
    }
    if ((step.stepState===StepState.CREATED_BY_DELEGATEPARTNER || step.stepState===StepState.UPDATED_BY_DELEGATEPARTNER || step.stepState===StepState.CREATED_BY_CLIENT || step.stepState===StepState.UPDATED_BY_CLIENT || step.stepState===StepState.CREATED_BY_AGENT || step.stepState===StepState.UPDATED_BY_AGENT || step.stepState===0) && step.toCompanyId !== Guid.Empty && user.companyId !== this.order.delegateCompanyID) {
      return true;
    }
    return false;
  }

  private removeDelegator(){
    //TTD-2422 remove delegate partner issue changes
    this.delegatedPartnerName = '';
    this.selectedDelegateObj = new DelegateCompanyModel(Guid.Empty, '', '', false, false);
    this.selectedDelegateObj.companyEmail = '';
    this.selectedDelegateObj.firstName = '';
    this.selectedDelegateObj.invitedPersonEmailID = '';
    this.selectedDelegateObj.isAgent = false;
    this.selectedDelegateObj.isTier1 = false
    this.selectedDelegateObj.lastName = '';
    this.isShowRemoveDelegator = false;
    this.isDelegatedPartnerRemoved = true;
    this.wantToDelegate = false;
  }
  // TTD-3619 showing Add ref modal for mainChain
  private showAddRefModalForMc(stepId:number){
    this.isShowAddRefModal = true;
    this.chainType = 'mainChain';
    const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
    this.stepIndex = index;
    this.referenceNumber = '';
  }
  // TTD-3619 showing Add ref modal for parallelChain
  private showAddRefModalForPc(stepId: number,parentStepId: number,indexOfChain: number){
    this.isShowAddRefModal = true;
    this.chainType = 'parallelChain';
    if(stepId!==-1){
      const index = this.getParallelChain(
        indexOfChain,
        parentStepId.toString()
      ).findIndex((t) => t.stepId === stepId);
      this.indexOfChain = indexOfChain;
      this.indexOfChainStep = index;
      this.parentStepId = parentStepId;
    }
    this.referenceNumber = ''
  }
  //TTD-3619 updaing refNumber
  private updateReferenceNumber(){
    const user = store.getters.user as UserModel;
    if(this.chainType === 'mainChain'){
      this.orderLineSteps[this.stepIndex].footprintRefNo = this.referenceNumber;
      if(this.referenceNumber!==''){
        this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
        this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
      }
    }else{
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].footprintRefNo = this.referenceNumber;
      if(this.referenceNumber!==''){
        this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
          this.indexOfChainStep
        ].footprintRefID = uuidv4();
        this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
          this.indexOfChainStep
        ].footprintRefNoUpdatedBy = user.companyId;
      }
      const parentId = this.parentStepId !== null ? Number.parseInt(this.parentStepId.toString()) : null;
      const index = this.orderLineSteps.findIndex((o) => o.stepId === parentId);
      const ele = this.orderLineSteps.filter((o) => o.stepId === parentId);
        this.orderLineSteps.splice(index, 1);
        this.orderLineSteps.splice(index, 0, ele[0]);
    }
    this.isShowAddRefModal = false;
  }
  //validation for reference number input field.
  private filterInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const filteredValue = input.value.replace(/[^a-zA-Z0-9.,+/&-]/g, '');
    input.value = filteredValue;
    this.referenceNumber = filteredValue;
}
  //commenting these changes not required now sprint-18
  // private resetSteps(){
  //   this.reset(this.defaultSteps);
  //   this.close(false);
  // }

  //assigning selected delegated partner details if delegated partner selected
  @Watch('allSupplyChainPartners')
  private fetchSupplyChainPartners(): void{
    if(this.allSupplyChainPartners.length > 0){
      if(this.delegatedPartnerName!==''){
        const id = this.orderLine[0].delegateCompanyID;
        if(id!==''&& id!==Guid.Empty){
          this.setDelegated(id);
        }
      }
    }
  }

  @Emit()
  private close(created: boolean): void {}

  @Emit()
  private back(orderLineSteps: OrderLineStepModel[]): void {}

  @Emit()
  private confirmOrderLine(
    orderLine: OrderLineDisplayModel[],
    orderLineSteps: OrderLineStepModel[],
    isDelegated: boolean,
    selectedDelegatePartner: DelegateCompanyModel,
    isDelegatedPartnerRemoved: boolean
  ): void {}
  //commenting these changes not required now sprint-18
  // @Emit()
  // private reset(rawSteps:OrderLineStepModel[]): void{}
}
