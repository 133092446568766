import { Vue, Component } from 'vue-property-decorator';
import { ActionTypes } from '@/store/actions/actions';
import { mapGetters } from 'vuex';
import _ from 'underscore';

// Services

// Components
import UploadEvidence from '@/views/orderEvidence/components/uploadEvidence.vue';
import ApproveRejectEvidence from '@/views/orderEvidence/components/approveRejectEvidence.vue';

// Models
import { EvidenceStepCountModel, OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { UserModel } from '@/models/userModel';

@Component({
  components: { UploadEvidence, ApproveRejectEvidence },
  computed: {
    ...mapGetters([
      'orderLineEvidenceStepsLoading',
      'orderLineEvidenceSteps',
      'orderLineEvidenceNeighbourSteps',
      'orderLineEvidenceApproveSteps',
      'user',
    ]),
  },
})
export default class OrderEvidence extends Vue {
  private orderLineEvidenceStepsLoading!: boolean;

  private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

  private orderLineEvidenceNeighbourSteps!: OrderLineEvidenceStep[];

  private orderLineEvidenceApproveSteps!: OrderLineEvidenceStep[];

  private steps: OrderLineEvidenceStep[] = [];

  private showUploadEvidence: boolean = false;
  private showResubmitEvidence: boolean = false;

  private showApproveRejectEvidence: boolean = false;

  private isLoadingUpload: boolean = false;
  private isLoadingResubmit: boolean = false;
  private isLoadingApproveReject: boolean = false;

  private user!: UserModel;

  public constructor() {
    super();
  }

  private async created(): Promise<void> {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = false;

    if (this.orderLineEvidenceSteps.length === 0 && !this.orderLineEvidenceStepsLoading) {
      await this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS);
    }
    if (this.$route.params.openUploadEvidence !== undefined && !this.orderLineEvidenceStepsLoading) {
      this.navUploadEvidence();
    }
    if (this.$route.params.openApproveEvidence !== undefined && !this.orderLineEvidenceStepsLoading) {
      this.navApproveRejectEvidence();
    }
  }

  private reloadEvidence(): void {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = false;
    this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS);
    this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT);
  }

  private get uploadEvidenceCount(): number {
    const pendingSteps = this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.Pending
    );
    return _.uniq(pendingSteps, 'orderID').length;
  }

  private get approveEvidenceCount(): number {
    const waitingApprovalSteps = this.orderLineEvidenceApproveSteps.filter(
      (s) => s.status === OrderLineStepStatus.WaitingForApproval
    );
    const uniqApproveStepOrdersCount = _.uniq(waitingApprovalSteps, 'orderID').length;
    // after confirm approve reject sync count on dhboard action for today
    const evidenceCount: EvidenceStepCountModel = {
      success: true,
      orderLineSteps: this.uploadEvidenceCount,
      approvedSteps: uniqApproveStepOrdersCount,
      orders: [],
      provideFootprint: 0
    }
    this.$store.commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT, evidenceCount);
    return uniqApproveStepOrdersCount;
  }

  private get reSubmitEvidenceCount(): number {
    const rejectedSteps = this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.Rejected
    );
    return _.uniq(rejectedSteps, 'orderID').length;
  }

  private navUploadEvidence(): void {
    this.isLoadingUpload = true;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = false;

    this.steps = this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.Pending
    );

    setTimeout(
      (app: any) => {
        app.showUpload();
      },
      100,
      this
    );
  }

  private navApproveRejectEvidence(): void {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = true;

    this.steps = this.orderLineEvidenceApproveSteps.filter(
      (s) => s.status === OrderLineStepStatus.WaitingForApproval
    );
    setTimeout(
      (app: any) => {
        app.showApproveRejectEvidence = true;
      },
      100,
      this
    );
  }
  private closeApproveRejectEvidence(): void {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = false;
    this.showApproveRejectEvidence = false;
  }

  private navReSubmitEvidence(): void {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = true;
    this.isLoadingApproveReject = false;
    this.steps = this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.Rejected
    );
    setTimeout(
      (app: any) => {
        app.showResubmit();
      },
      100,
      this
    );
  }

  private showUpload(): void {
    this.showUploadEvidence = true;
  }

  private closeUploadEvidence(): void {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = false;
    this.showUploadEvidence = false;
  }

  private showResubmit(): void {
    this.showResubmitEvidence = true;
  }

  private closeResubmitEvidence(): void {
    this.isLoadingUpload = false;
    this.isLoadingResubmit = false;
    this.isLoadingApproveReject = false;
    this.showResubmitEvidence = false;
  }
}
